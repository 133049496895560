@import '../styles/global.scss';

.contact {
  display: grid;
  place-items: center;
}

.container {
  background-color: var(--nav-color);
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  display: grid;
  margin: 50px 0 200px 0;
  max-width: 800px;
  padding: 20px;
  scroll-margin-top: 110px;
  width: 80%;
}

.heading {
  background-color: transparent;
  margin-bottom: 20px;
  margin-top: 0;
}

.form {
  background-color: var(--nav-color);
  display: grid;
  grid-template-areas:
    'name'
    'email'
    'message'
    'submit';
    grid-gap: 20px;
}

input, textarea {
  background-color: var(--mute-gray);
  border: transparent;
  border-bottom: 2px solid transparent;
  border-radius: 5px;
  color: #212427;
  font-family: var(--body-text);
  font-size: 16px;
  padding: 20px;

  &:focus {
    border-image: linear-gradient(to right, var(--pink), var(--orange)) 1;
    outline: 0;
  }
}

.name {
  grid-area: name;
}

.email {
  grid-area: email;
}

.message {
  grid-area: message;
  resize: none;
}

.button {
  background-image: linear-gradient(to right, var(--pink), var(--orange), var(--pink), var(--orange));
  border: 1px solid transparent;
  border-radius: 5px;
  color: var(--primary-text-color);
  font-size: 16px;
  grid-area: submit;
  justify-content: center;
  justify-self: right;
  padding: 15px;
  width: 150px;
  background-size: 300% 100%;
  transition: all 0.4s ease-in-out;

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-position: 100% 0;
    color: var(--primary-text-color);
    cursor: pointer;
    transition: all 0.4s ease-in-out;
  }
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

.error,
.thanks {
  background-color: transparent;
  font-family: var(--heading-text);
  margin: auto;
  text-align: center;
  transition: all 0.4s ease-in-out;
}

@media only screen and (min-width: 600px) {
  .form {
    display: grid;
    grid-template-areas:
      'name email'
      'message message'
      '. submit';
      grid-gap: 20px;
  }
}

@media only screen and (min-width: 500px) {
  .container {
    padding: 40px;
  }
}
