@import '../styles/global.scss';

@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translateX(-75px);
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(75px);
  }
  to {
    opacity: 1;
  }
}

.container {
  justify-content: center;
  align-items: end;
  display: grid;
  grid-template-areas:
    'hero'
    'down';
  height: 100vh;
  margin: auto;
  position: relative;
  text-align: center;
}

.intro {
  background-color: transparent;
}

.hero {
  background-color: transparent;
  padding: 30px;
}


.heroName {
  animation: fade-in-right 3s forwards;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background-color: transparent;
  color: var(--primary-text-color);
  font-size: 50px;
  font-weight: 700;
  letter-spacing: .10em;
  text-transform: uppercase;
}

.heroTitle {
  animation: fade-in-left 3s forwards;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background: linear-gradient(to right, var(--pink), var(--orange));
  box-shadow: var(--box-shadow);
  color: var(--primary-bg-color);
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.20em;
  margin-top: 20px;
  padding: 5px;
  text-transform: uppercase;
}

.heroWrapper {
  background-color: transparent;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 10px, 0);
  }
}

.down {
  bottom: 15%;
  position: absolute;
  align-self: end;
  animation: bounce 0.3s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 1);
  animation-iteration-count: infinite;
  justify-self: center;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s, opacity 1.0s linear;

  a {
    border-bottom: 0;
  }

  svg {
    color: var(--primary-text-color);
    height: 40px;
    top: 0;
    transition: all 0.1s ease-in-out;
    position: relative;
    width: 40px;
  }
}

.down__show {
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 720px) {
  .heroName {
    font-size: 75px;
  }

  .heroTitle {
    font-size: 30px;
  }
}

@media only screen and (min-width: 420px) {
 .down {
    bottom: 5%;
  }
}
