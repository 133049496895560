:root {
  --mute-gray: #e6e6e6;
  --orange: #ff9472;
  --pink: #f2709c;
  --primary-text-color: #212427;
  --primary-bg-color: #f7fafc;
  --nav-color: #f8f8f8;
  --toggle-border: #2d2f39;

  --body-text: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --heading-text: 'Poppins', sans-serif;

  --box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

[data-theme='dark'] {
  --primary-text-color: #f7fafc;
  --primary-bg-color: #212427;
  --nav-color: #171818;
}

* {
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  font-family: var(--body-text);
  font-size: 18px;
  margin: 0;
  transition: all 0.1s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  caret-color: rgba(0, 0, 0, 0);
  font-family: var(--heading-text);
}

h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

html {
  scroll-behavior: smooth;
}

p {
  margin-bottom: 20px;
}

section {
  scroll-margin-top: 60px;

  a {
    border-bottom: 2px solid var(--pink);
    color: var(--primary-text-color);
    font-weight: 500;
    text-decoration: none;
  }

  a:hover {
    color: var(--pink);
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    padding-bottom: 10px;

    &:before {
      content: '❯';
      color: var(--pink);
      font-size: 14px;
      margin: 0 8px;
    }
  }
}
