.container {
  padding-top: 80px;
}

.construction {
  display: grid;
  margin: auto;
  max-width: 800px;
  padding: 50px;
}

.description {
  font-size: 30px;
  margin: auto;
  padding-top: 60px;
}

.tools {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  display: grid;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  svg {
    height: auto;
    margin: auto;
    max-width: 300px;
    width: 70%;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media only screen and (min-width: 600px) {
  .description {
    font-size: 40px;
  }
}