@import '../styles/global.scss';

.up {
  background-color: transparent;
  bottom: 48px;
  display: grid;
  place-items: end;
  position: fixed;
  right: 20px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1.0s linear;

  a {
    background-color: transparent;
  }

  svg {
    border-radius: 50px;
    fill: var(--primary-text-color);
    height: 40px;
    padding: 5px;
    margin: 10px;
    transition: all 0.2s ease-in-out;
    width: 40px;

    &:hover {
      cursor: pointer;
      top: -5px;
    }
  }
}

.item__visible {
  visibility: visible;
  opacity: 1;
}
