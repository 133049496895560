@import '../styles/global.scss';

.bar {
  background: linear-gradient(to right, var(--pink), var(--orange));
  height: 10px;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 100;
}

.nav {
  align-items: center;
  background-color: var(--nav-color);
  box-shadow: var(--box-shadow);
  display: grid;
  grid-template-areas: 'logo menu';
  grid-template-columns: max-content 1fr;
  gap: 0;
  height: 40px;
  margin-top: 10px;
  padding: 10px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
}

.list {
  background-color: transparent;
  display: flex;
  font-family: var(--heading-text);
  gap: 10px;
  grid-area: menu;
  justify-content: center;
  margin: 0;
  margin-right: 60px;
  place-items: center;
}

.item {
  background-color: transparent;
  caret-color: rgba(0, 0, 0, 0);
  font-weight: 700;
  list-style-type: none;
  text-transform: uppercase;

  a {
    background-color: transparent;
    border-bottom: 3px solid transparent;
    color: var(--primary-text-color);
    text-decoration: none;
  }

  a:hover {
    border-image: linear-gradient(to right, var(--pink), var(--orange)) 1;
  }
}

.logo {
  background-color: var(--primary-text-color);
  border-radius: 5px;
  color: var(--primary-bg-color);
  font-size: 25px;
  font-weight: 700;
  grid-area: logo;
  justify-content: flex-start;
  padding: 2px 8px;
  text-decoration: none;
}

@media only screen and (min-width: 420px) {
  .list {
    gap: 20px;
    justify-content: flex-end;
  }

  .logo {
    font-size: 30px;
  }

  .nav {
    padding: 10px 50px;
  }
}
