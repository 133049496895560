@import '../styles/global.scss';

.footer svg {
  color: var(--primary-text-color);
  height: 40px;
  margin-bottom: 10px;
  transition: all 0.1s ease-in-out;
  width: 40px;
}

.icons {
  display: grid;
  gap: 10px;
  grid-template-areas: 'a b c';
  justify-content: center;
  max-width: 200px;
  margin: auto;
  margin-bottom: 0;
  padding-bottom: 50px;

  a {
    border-bottom: none;
  }

  .icon {
    position: relative;
    top: 0;
  }

  .icon:hover {
    top: -5px;
  }
}
